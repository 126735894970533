// GENERAL

* {
	scroll-behavior: smooth;
}

body {
	overflow-x: hidden;
}

.dark {
	body {
		background-color: #151515;
	}
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}

a {
	cursor: pointer;
}

.underlined {
	text-decoration-line: underline;
	text-decoration-color: #000 !important;
}

iframe.maps {
	overflow: hidden;
}

.required-field {
	color: #bd1930;
	font-weight: 400;
}

.z-modal-bg {
	z-index: 200;
}

.z-modal {
	z-index: 250;
}

.z-100 {
	z-index: 100;
}

.z-90 {
	z-index: 90;
}

.m-0-auto {
	margin: 0 auto;
}

.logo {
	&-header {
		display: none;

		&-dark {
			display: none;
		}
	}

	&-home {
		display: flex;

		&-dark {
			display: none;
		}
	}
}

@media (max-width: 1023px) {
	.logo {
		&-default {
			display: none;
		}

		&-header {
			display: block;

			&-dark {
				display: none;
			}
		}
	}
}

h1 {
	&.home-site-name {
		visibility: hidden;
	}
}

html[class*=dark] {
	.underlined {
		text-decoration-color: #fff !important;
	}

	iframe.maps {
		filter: invert(1) hue-rotate(180deg) grayscale(0.7)
	}

	.logo {
		&-home {
			display: none;

			&-dark {
				display: flex;
			}
		}
	}

	@media (max-width: 1023px) {
		.logo {
			&-default {
				display: none;
			}

			&-header {
				display: none;

				&-dark {
					display: block;
				}
			}
		}
	}
}

img {
	&.placeholder {
		background-color: #4b5563 !important;
	}
}



// SOCIAL

.social-media {
	a {
		z-index: 10;
		color: white;
		width: 48px;
		height: 48px;
	}
	svg {
		width: 48px;
		height: 48px;
	}
}



// SCROLLDOWN

.scroll-down {
	/*position: absolute;
	bottom: 40px;
	left: 0;*/
	width: 100%;
	z-index: 1;

	button {
		background: transparent;
		outline: 0;
		border: 0;
		cursor: pointer;
	}

	&.light {
		span {
			color: #3A3B44;
		}

		.mouse {
			border-color: #3A3B44;

			.wheel {
				background: #3A3B44;
			}
		}
	}
}

@-webkit-keyframes ani-mouse {
	0% {
		top: 29%;
	}

	15% {
		top: 50%;
	}

	50% {
		top: 50%;
	}

	100% {
		top: 29%;
	}
}

@-moz-keyframes ani-mouse {
	0% {
		top: 29%;
	}

	15% {
		top: 50%;
	}

	50% {
		top: 50%;
	}

	100% {
		top: 29%;
	}
}

@keyframes ani-mouse {
	0% {
		top: 29%;
	}

	15% {
		top: 50%;
	}

	50% {
		top: 50%;
	}

	100% {
		top: 29%;
	}
}

.mouse-wrapper {
	color: #3A3B44;
	font-size: 14px;
	display: block;
	max-width: 100px;
	margin: auto;
	text-align: center;
	cursor: pointer;

	span {
		color: #3A3B44;
	}

	&:hover {
		color: #AAAAAF;
	}
}

.mouse {
	border: solid 2px #3A3B44;
	border-radius: 16px;
	display: block;
	margin: auto;
	margin-top: 10px;
	height: 26px;
	position: relative;
	width: 20px;

	.wheel {
		background: #3A3B44;
		border-radius: 100%;
		display: block;
		position: absolute;
		top: 8px;
		left: 50%;
		transform: translateX(-50%);
		height: 4px;
		width: 4px;
		-webkit-animation: ani-mouse 2s linear infinite;
		-moz-animation: ani-mouse 2s linear infinite;
		animation: ani-mouse 2s linear infinite;
	}
}

.circle {
	border-radius: 100%;
}

.dark {
	.mouse-wrapper {
		color: #FAFAFC;

		span {
			color: #FAFAFC;
		}

		&:hover {
			color: #AAAAAF;
		}
	}

	.mouse {
		border: solid 2px #FAFAFC;

		.wheel {
			background: #FAFAFC;
		}
	}
}



// DIRECTORIO

table.directory {
	border-collapse: collapse;
	width: 100%;
	margin: 2.5rem 0;
	table-layout: auto;
	text-align: left;

	thead {
		background-color: #EAEAED;
		border-bottom: 3px solid;
	}

	tr {
		box-sizing: border-box;
		border-width: 0;
		border-style: solid;
		border-color: #EAEAED;
	}

	th,
	td {
		border-width: 1px!important;
		border-color: #EAEAED;
		padding: .75rem!important;
	}
}

html[class*=dark] table.directory {
	thead {
		background-color: #5A5B63;
		color: #2A2B35;
	}

	th,
	td {
		border-color: #5A5B63;
	}
}



// PREGUNTAS

.container-accordion {
	h2 {
		font-size: 1.2em;
		font-weight: 600;
		margin-bottom: 32px;
	}

	h3 {
		font-size: 1em;
		font-weight: 400;
		margin-bottom: 10px;
	}

	.chevron-down {
		flex-shrink: 0;
		margin-left: auto;
		transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
	}

	.szh-accordion {
		margin-bottom: 1.5rem;

		&__item {
			border: 1px solid #EAEAED;
			border-top-width: 0;

			&-btn {
				cursor: pointer;
				display: flex;
				align-items: center;
				width: 100%;
				margin: 0;
				height: 50px;
				text-align: left;
				color: #3A3B44;
				padding: 1rem 1.25rem;
				background-color: transparent;
				border: none;
				transition: background-color 0.25s ease-in-out;

				&:hover {
					background-color: #F1F5F9;
					box-shadow: 0 0 0 1px var(--ifm-color-primary);
				}

				&:focus {
					position: relative;
					outline: none;
				}

				&:focus-visible {
					box-shadow: 0 0 0 3px var(--ifm-color-primary);
				}

				&:disabled {
					box-shadow: none;
					cursor: auto;
					color: #EAEAED;
				}
			}

			&-content {
				transition: height 0.25s cubic-bezier(0, 0, 0, 1);
			}

			&-panel {
				padding: 1rem 1.25rem;
				color: #3A3B44;
			}

			&--expanded {
				composes: button;
				box-shadow: inset 0 -1px 0 0 var(--ifm-color-emphasis-300);

				.szh-accordion__item-btn {
					background-color: #EAEAED;
				}
				.chevron-down {
					transform: rotate(180deg);
				}
			}

			&:last-of-type {
				border-bottom-left-radius: 6px;
				border-bottom-right-radius: 6px;

				.button:not(.buttonExpanded) {
					border-bottom-left-radius: 5px;
					border-bottom-right-radius: 5px;
				}
			}

			&:first-of-type {
				border-top-width: 1px;
				border-top-left-radius: 6px;
				border-top-right-radius: 6px;

				.button {
					border-top-left-radius: 5px;
					border-top-right-radius: 5px;
				}
			}
		}
	}

	@supports not selector(:focus-visible) {
		.szh-accordion__item-btn:focus {
			box-shadow: 0 0 0 3px var(--ifm-color-primary);
		}
	}

	@media (max-width: 1023px) {
		.szh-accordion__item-btn {
			height: 80px;
		}
	}
}

html[class*=dark] .container-accordion .szh-accordion__item {
	border-color: #5A5B63;

	&-panel {
		color: #FAFAFC;
	}

	&-btn {
		color: #FAFAFC;

		&:hover {
			background-color: #5A5B63;
		}
	}

	&--expanded {
		.szh-accordion__item-btn {
			color: #FAFAFC;
			background-color: #5A5B63;
		}
	}

	.chevron-down {
		filter: invert(1);
	}
}



// CONTACTO
.icon-twitter {
	margin-top: -2px !important;
}



// ALERTA

.alerta {
	position: relative;
	padding: 1.4em 1.4em 1.4em 3.4em;
	border-radius: 4px;
	border: 2px solid #7f8a9d;

	&:before {
		content: "?";
		color: #666;
		position: absolute;
		top: 1.15em;
		left: 0.7em;
		font-size: 22px;
		line-height: 1;
	}

	&.success {
		border-color: #f6ddcd;

		&:before {
			content: "??";
			color: #333;
		}
	}

	&.info {
		border-color: #ffd115;
		background-color: rgba(255, 209, 21, .1);

		&:before {
			content: "⚠️";
			color: #ffb000;
		}
	}

	&.error {
		border-color: #bd1930;
		background-color: rgba(189, 25, 48, .1);

		&:before {
			content: "⛔️";
			color: #ff6140;
		}
	}

	.link {
		text-decoration: underline;
		font-weight: 500;
	}
}


// LIGHTBOX

.lightbox {
	z-index: 999;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
}

.lightbox .image {
	max-height: 75vh;
	max-width: 85vw;
	object-fit: cover;
}

.lightbox .title,
.lightbox .captions {
	text-shadow: #FFF 1px 0 10px;
	max-width: 85vw;
}

.lightbox .lazy-load-image-background {
	display: block !important;
}

@media (max-width: 800px) {
	.lightbox .image,
	.lightbox .title,
	.lightbox .captions {
		max-width: 70vw;
	}
}

.dark {
	.lightbox .title,
	.lightbox .captions {
		text-shadow: #000 1px 0 10px;
		max-width: 85vw;
	}
}



// 360 GALLERY

.elastislide-list {
	list-style-type: none;
	display: none;
}
.no-js .elastislide-list {
	display: block;
}
.elastislide-carousel ul li {
	min-width: 20px
}
.elastislide-wrapper {
	position: relative;
	background-color: #fff;
	margin: 0 auto;
	min-height: 60px;
	box-shadow: 1px 1px 3px rgba(0,0,0,.2)
}
.elastislide-wrapper.elastislide-loading {
	background-repeat: no-repeat;
	background-position: center center
}
.elastislide-horizontal {
	padding: 10px 40px
}
.elastislide-vertical {
	padding: 40px 10px
}
.elastislide-carousel {
	overflow: hidden;
	position: relative
}
.elastislide-carousel ul {
	position: relative;
	display: block;
	list-style-type: none;
	padding: 0;
	margin: 0;
	-webkit-backface-visibility: hidden;
	-webkit-transform: translateX(0px);
	-moz-transform: translateX(0px);
	-ms-transform: translateX(0px);
	-o-transform: translateX(0px);
	transform: translateX(0px)
}
.elastislide-horizontal ul {
	white-space: nowrap
}
.elastislide-carousel ul li {
	margin: 0;
	-webkit-backface-visibility: hidden
}
.elastislide-horizontal ul li {
	height: 100%;
	display: inline-block
}
.elastislide-vertical ul li {
	display: block;
}
.elastislide-carousel ul li a {
	display: inline-block;
	width: 100%
}
.elastislide-wrapper nav span {
	position: absolute;
	width: 23px;
	height: 23px;
	text-indent: -9000px;
	cursor: pointer;
	opacity: .8
}
.elastislide-wrapper nav span:hover {
	opacity: 1
}
.elastislide-horizontal nav span {
	top: 50%;
	left: 10px;
	margin-top: -11px
}
.elastislide-vertical nav span {
	top: 10px;
	left: 50%;
	margin-left: -11px;
	background-position: -17px 5px
}
.elastislide-horizontal nav span.elastislide-next {
	right: 10px;
	left: auto;
	background-position: 4px -17px
}
.elastislide-vertical nav span.elastislide-next {
	bottom: 10px;
	top: auto;
	background-position: -17px -18px
}

.r-gallery {
	margin: 0 0 1.0em 0
}
.entry-header + .layout-full .r-gallery {
	margin: 1.0em 0
}
.r-gallery .media-box {
	margin: 0
}
.r-gallery .media-cell-desc {
	padding: 0 2em;
	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
	margin-top: -10px
}
.r-gallery .media-box .mask:before {
	display: none
}

#carousel {
	-webkit-transition: all 400ms ease-in-out !important;
	transition: all 400ms ease-in-out !important;
	-ms-overflow-style: none;
	scrollbar-width: none;
}
#carousel::-webkit-scrollbar {
	display: none;
}

.rg-gallery .elastislide-wrapper {
	background: #111;
	padding: 10px 28px;
	position: relative;
	box-shadow: none;
	border-radius: 10px;
}
.rg-gallery .elastislide-list li a {
	border: 2px solid transparent;
	opacity: 0.5;
	filter: alpha(opacity = 50);
	-webkit-touch-callout: none;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out
}
.rg-gallery ul.elastislide-list li {
	max-width: 76px;
	margin: 0 4px
}
.rg-gallery ul.elastislide-list li a:hover {
	opacity: 0.9;
	filter: alpha(opacity = 90)
}
.rg-gallery ul.elastislide-list li.selected a {
	border-color: #fff;
	opacity: 1.0;
	filter: alpha(opacity = 100)
}
.rg-gallery .elastislide-wrapper nav span {
	position: absolute;
	top: 0;
	left: 0px;
	width: 28px;
	height: 100%;
	margin-top: 0;
	text-indent: -9000px;
	cursor: pointer;
}

.rg-image-wrapper {
	position: relative;
	padding: 30px 0;
	min-height: 180px
}

.rg-title {
	height: 40px;
	display: block;
	color: white;
	background: rgba(61, 61, 61, 0.5);
	margin-top: 0px;
	position: absolute;
	z-index: 10;
	width: 100%;
	line-height: 2.5;
	text-align: center;
}

@media (max-width: 640px) {
	.rg-gallery ul.elastislide-list li {
		margin-top: -30px;
	}
}

.psv-fullscreen-emulation {
	z-index: 10;
}



// TIMELINE

.timeline {
	h3 {
		display: block;
		font-weight: 600;
		--tw-text-opacity: 1;
		color: #3A3B44;
		margin-top: 0.25rem;
	}

	h4 {
		text-transform: uppercase;
		letter-spacing: 0.025em;
		font-size: 0.875rem;
		line-height: 1.25rem;
		--tw-text-opacity: 1;
		color: #AAAAAF;
		margin-top: 0.5rem;
	}

	p {
		--tw-text-opacity: 1;
		color: #3A3B44;
		margin-top: 0.5rem;
	}

	a, a:hover {
		color: var(--tw-prose-links);
		font-weight: 500;
		text-decoration: underline;
	}
}

@media (min-width: 1024px) {
	.timeline {
		h3 {
			font-size: 1.25rem;
			line-height: 1.75rem;
		}
	}
}

@media (max-width: 767px) {
	.skeleton-timeline-image {
		width: 100% !important;
		height: 180px;
	}
}

.dark {
	.timeline {
		h3 {
			color: #FAFAFC;
		}
		h4 {
			color: #AAAAAF;
		}
		p {
			color: #FAFAFC;
		}
	}
}


// NOTEBOOK


:root {
  --main-line-color: hsl(234, 62%, 86%);
  --side-line-color: hsl(350, 100%, 91%);
  --paper-color: hsl(0, 15%, 95%);
  --ink-color: hsl(0, 0%, 12%);
  --line-thickness: 1px;
  --top-space: 2lh;
  --dark-main-line-color: #2A2B35;
  --dark-side-line-color: hsl(350, 48%, 61%);
  --dark-paper-color: #151515;
}

.notebook {
	background: var(--paper-color);
	padding: calc(var(--top-space) - 0.5lh) 3rem 1rem 5.5rem;
	line-height: 1.2;
	color: var(--ink-color);
	background-image:
		linear-gradient(
		90deg,
		transparent,
		transparent 4rem,
		var(--side-line-color) 4rem,
		var(--side-line-color) 4.25rem,
		transparent 4.25rem
	),
	linear-gradient(
		var(--paper-color),
		var(--paper-color) var(--top-space),
		transparent var(--top-space)
	),
	repeating-linear-gradient(
		transparent,
		transparent calc(1lh - var(--line-thickness)),
		var(--main-line-color) calc(1lh - var(--line-thickness)),
		var(--main-line-color) 1lh,
		transparent 1lh
	);
	box-shadow: 0 0 .5rem rgb(0 0 0 / 0.25);

	ol {
		line-height: 1.6;
	}

	a {
		&:hover{
			text-decoration-line: underline;
			text-decoration-color: #000 !important;
		}
	}
}

.dark {
	.notebook {
		background: var(--dark-paper-color);
		color: rgb(var(--c-neutral-300));
		background-image:
			linear-gradient(
			90deg,
			transparent,
			transparent 4rem,
			var(--dark-side-line-color) 4rem,
			var(--dark-side-line-color) 4.25rem,
			transparent 4.25rem
		),
		linear-gradient(
			var(--dark-paper-color),
			var(--dark-paper-color) var(--top-space),
			transparent var(--top-space)
		),
		repeating-linear-gradient(
			transparent,
			transparent calc(1lh - var(--line-thickness)),
			var(--dark-main-line-color) calc(1lh - var(--line-thickness)),
			var(--dark-main-line-color) 1lh,
			transparent 1lh
		);
	}
}


// MODAL

.modal-dialog-alert {
	z-index: 99999;
}


// ARTICLE

.article-skeleton-image {
	float: right;
	margin: 0;
	padding: 0;
}

.article-skeleton-image-full {
	width: 100%;
	margin: 0;
	padding: 0;
	line-height: 1;

	.react-loading-skeleton {
		width: 100% !important;
	}
}

.article-skeleton-text-center {
	display: none;
}

@media (min-width: 768px) and (max-width: 1023px) {
	.article-skeleton-last-article {
		width: 100%;

		.react-loading-skeleton {
			width: 100% !important;
		}
	}
}

@media (max-width: 767px) {
	.article-skeleton-image {
		width: 100%;
		margin-bottom: 20px;

		.react-loading-skeleton {
			width: 100% !important;
		}
	}

	.article-skeleton-last-article {
		width: 100%;
		height: 405px !important;

		.react-loading-skeleton {
			width: 100% !important;
			height: 405px !important;
		}
	}

	.article-skeleton-text-left {
		display: none;
	}

	.article-skeleton-text-center {
		display: block;
	}
}

.article-content {
	color: #3A3B44;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		color: #3A3B44;
	}

	.first-paragraph:first-letter {
		font-size: 56px;
		line-height: 1;
		font-weight: 800;
		float: left;
		padding: 0px 12px 0px 0;
	}

	a {
		&:hover{
			text-decoration-line: underline;
			text-decoration-color: #000 !important;
		}
	}

	figure[class^="post-image"] {
		img {
			border-radius: 0.75rem;
		}

		img,
		figcaption {
			margin-top: 0;
			margin-bottom: 0;
		}

		figcaption {
			color: #AAAAAF;
			font-size: 0.875rem;
			line-height: 1.25rem;
			padding-top: 12px;
		}
	}

	.post-image-full-width {
		img {
			width: 100%;
			max-width: 1024px;
		}

		figcaption {
			text-align: center;
		}
	}

	.post-image-horizontal-center {
		width: 680px;
		margin: 0 auto;

		figcaption {
			text-align: center;
		}
	}

	.post-image-vertical-center {
		width: 350px;
		margin: 0 auto;

		figcaption {
			text-align: center;
		}
	}

	.post-image-horizontal-left {
		float: left;
		margin: 10px 20px 10px 0px;
		width: 350px;
	}

	.post-image-horizontal-right {
		float: right;
		margin: 10px 0 10px 20px;
		width: 350px;
	}

	.post-image-vertical-left {
		float: left;
		margin: 10px 20px 10px 0px;
		width: 280px;
	}

	.post-image-vertical-right {
		float: right;
		margin: 10px 0 10px 20px;
		width: 280px;
	}

	@media (max-width: 767px) {
		.post-image-full-width,
		.post-image-horizontal-center,
		.post-image-horizontal-left,
		.post-image-horizontal-right {
			width: 100%;
			margin: 0 auto;
			margin-bottom: 25px;

			img {
				width:100%;
				height: auto;
			}

			figcaption {
				text-align: center;
			}
		}

		.post-image-vertical-center,
		.post-image-vertical-left,
		.post-image-vertical-right {
			width: 100%;
			margin: 0 auto;
			margin-bottom: 25px;

			img {
				margin: 0 auto;
			}

			figcaption {
				text-align: center;
			}
		}
	}

	.container-youtube {
		width: 100%;
		max-width: 740px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 1.5em;

		iframe {
			width: 100%;
			aspect-ratio: 16 / 9;
		}
	}

	.container-soundcloud {
		width: 100%;
		max-width: 740px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 1.5em;

		iframe {
			width: 100%;
			height: 150px;
		}
	}

	.gallery-link,
	.gallery-image {
		display: inline-block;
		margin: 0;
	}

	.gallery-description {
		color: var(--tw-prose-captions);
		font-size: 0.875rem;
		line-height: 1.25rem;
		text-align: center;
	}

	@media (min-width: 1024px) {
		.gallery-container {
			.lg-w-1\/4 {
				width: 25%;
			}

			.lg-w-1\/2 {
				width: 50%;

				img {
					width: 100vw;
					height: 240px;
					object-cover: cover;
					object-position: bottom right;
				}
			}

			.lg-w-1\/3 {
				width: 33.333333%;
			}

			.lg-w-full {
				width: 100%;

				img {
					width: 100vw;
					height: 420px;
					object-cover: cover;
					object-position: bottom right;
				}
			}
		}
	}

	@media (min-width: 768px) and (max-width: 1023px) {
		.gallery-container {
			.md-w-1\/2 {
				width: 50%;

				img {
					width: 100vw;
					height: 280px;
					object-cover: cover;
					object-position: bottom right;
				}
			}

			.md-w-1\/3 {
				width: 33.333333%;
			}

			.md-w-full {
				width: 100%;

				img {
					width: 100vw;
					height: 420px;
					object-cover: cover;
					object-position: bottom right;
				}
			}
		}
	}

	@media (max-width: 767px) {
		.gallery-container {
			.sm-w-1\/2 {
				width: 50%;

				img {
					width: 100vw;
				}
			}

			.sm-w-full {
				width: 100%;

				img {
					width: 100vw;
					height: 380px;
					object-cover: cover;
					object-position: bottom right;
				}
			}
		}
	}

	.menu {
		background-color: rgba(var(--c-neutral-100),1);
		font-size: 0.875rem;
		line-height: 1.25rem;
		text-align: center;
		padding: 25px 0;
	}
}

.dark {
	.article-content {
		color: #FAFAFC;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p {
			color: #FAFAFC;
		}

		.menu {
			background-color: rgba(var(--c-neutral-800),1);
			color: rgba(var(--c-neutral-100),1);
		}
	}
}

.field-hidden,
.comment-hidden,
.sources-hidden {
	display: none;
}

.card-testimonial,
.testimonial-container {
	height: 280px;
}

.color-facebook {
	color: #1877F2;
}

.color-instagram {
	color: #C13584;
}

.color-twitter {
	color: #14171A;
}

.color-youtube {
	color: #CD201F;
}

.color-tiktok {
	color: #040404;
}

.color-facebook,
.color-instagram,
.color-twitter,
.color-youtube,
.color-tiktok {
	&:hover {
		filter: brightness(75%);
	}
}

.dark {
	.color-twitter,
	.color-tiktok {
		color: #dddddd;
	}
}

footer {
	.color-twitter,
	.color-tiktok {
		color: #dddddd;
	}
}



// PAGES

.page-content {
	color: #3A3B44;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		color: #3A3B44;
	}
}

.dark {
	.page-content {
		color: #FAFAFC;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		color: #FAFAFC;
	}
}

.lazy-load-image-loaded {
	display: unset !important;
}

@media (max-width: 640px) {
	.home-testimonial-date {
		margin-top: -8px;
	}

	.guestbook-badge {
		margin-left: -5px;
	}
}



// COOKIES

.cookies-wrapper {
	display: block;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 100000;
	background-color: #f5f5f5;
	border-top: 1px solid #ebebeb;
	font-weight: 500
}

.cookies-container {
	max-width: 1024px;
	width: 100%;
	display: -webkit-box;
	display: flex;
	color: #3A3B44;
	-webkit-box-pack: justify;
	justify-content: space-between;
	margin: 0 auto;
	padding: 1em;
	overflow: hidden
}

.cookies-container p {
	margin: 0;
	width: 90%;
	float: left;
	color: #3A3B44;
	font-size: 11px;
	line-height: 1.2;
	align-self: center;
}

.cookies-container a {
	color: inherit;
	text-decoration: underline;
}

.cookies-container button {
	display: inline-block;
	color: inherit;
	text-transform: uppercase;
	background: 0 0;
	border: none;
	padding: 0;
	font-size: 11px;
	cursor: pointer;
	outline: inherit;
	font-weight: 600;
	padding: 0 16px;
	border-radius: 25px;
}

.cookies-container button:hover,
.cookies-container button:active {
	color: #FAFAFC;
	background-color: #3A3B44;
}

.dark {
	.cookies-wrapper {
		background-color: #2A2B35;
		border-top: 1px solid #5A5B63;
	}

	.cookies-container,
	.cookies-container p {
		color: #FAFAFC;
	}

	.cookies-container button:hover,
	.cookies-container button:active {
		color: #3A3B44;
		background-color: #FAFAFC;
	}
}



// COPYRIGHT

img {
	/*pointer-events: none;*/
	-webkit-touch-callout: none;
	-moz-touch-callout: none;
	-ms-touch-action: none;
	-o-touch-callout: none;
	touch-callout: none;
}

@media print {
	img,
	svg,
	figure {
		visibility: hidden;
	}
}